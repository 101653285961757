<template>
  <svg width="22" height="22" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :style="style">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M31 15.5C31 6.93958 24.0604 0 15.5 0C6.93958 0 0 6.93958 0 15.5C0 24.0604 6.93958 31 15.5 31C24.0604 31 31 24.0604 31 15.5ZM9.73506 1.85254C11.5606 1.0804 13.5002 0.688889 15.5 0.688889C17.4998 0.688889 19.4394 1.0804 21.2649 1.85254C23.0286 2.59848 24.6126 3.66652 25.973 5.02696C27.3335 6.38741 28.4015 7.97144 29.1475 9.73506C29.9196 11.5606 30.3111 13.5002 30.3111 15.5C30.3111 17.4998 29.9196 19.4394 29.1475 21.2649C28.4015 23.0286 27.3335 24.6126 25.973 25.973C24.6126 27.3335 23.0286 28.4015 21.2649 29.1475C19.4394 29.9196 17.4998 30.3111 15.5 30.3111C13.5002 30.3111 11.5606 29.9196 9.73506 29.1475C7.97144 28.4015 6.38741 27.3335 5.02696 25.973C3.66652 24.6126 2.59848 23.0286 1.85254 21.2649C1.0804 19.4394 0.688889 17.4998 0.688889 15.5C0.688889 13.5002 1.0804 11.5606 1.85254 9.73506C2.59848 7.97144 3.66652 6.38741 5.02696 5.02696C6.38741 3.66652 7.97144 2.59848 9.73506 1.85254Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.60714 8H21.3929C22.2805 8 23 8.71955 23 9.60715V18.1787C23 19.0663 22.2805 19.7858 21.3929 19.7858H15.5273L12.1084 22.8624C11.9948 22.9647 11.843 23.0135 11.6912 22.9968C11.3971 22.9643 11.185 22.6996 11.2175 22.4055L11.5089 19.7858H9.60714C8.71954 19.7858 8 19.0663 8 18.1787V9.60715C8 8.71955 8.71954 8 9.60714 8ZM21.3928 18.7144C21.6887 18.7144 21.9286 18.4745 21.9286 18.1787H21.9286V9.60715C21.9286 9.31128 21.6887 9.07143 21.3929 9.07143H9.60714C9.31126 9.07143 9.07142 9.31128 9.07142 9.60715V18.1787C9.07142 18.4745 9.31126 18.7144 9.60714 18.7144H12.1073C12.1272 18.7144 12.1471 18.7155 12.1669 18.7177C12.4609 18.7505 12.6726 19.0155 12.6398 19.3096L12.4378 21.1251L14.9632 18.8521C15.0616 18.7635 15.1892 18.7144 15.3216 18.7144H21.3928Z"/>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconMessage = Vue.extend({
  name: 'icon-message',
  props: {
    color: {
      type: String,
      default: '#42BB92',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});
export default IconMessage;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1,
  .cls-3 {
    fill: none;
  }

  .cls-1 {
    stroke: #000;
  }

  .cls-2 {
    stroke: none;
  }
</style>
