<template>
  <div :class="{
    'show-all' : notes.length > 1,
    'show-action' : showActionButton
  }">
    <div class="field-header" v-if="showHeadline || showActionButton">
      <div class="title" v-if="showHeadline">{{$t('title.notes')}}</div>
      <div class="action">
        <a class="btn cur-p" @click="seeAllNotes()">{{$t('button.seeAll')}}</a>
      </div>
    </div>
    <div class="field-content">
      <div v-for="(note, key) in notes"
           :key="key"
           class="note"
           v-show="key < 1 || showAll"
      >
        <div class="note-head">
          <div class="avatar"
               :style="{
                  'background-image': 'url(' + setAvatar(note.owner.photo_url) + ')'
               }"
          ></div>

          <div class="note-meta">
            <div class="author">
              <!-- TODO: разобраться, почему из company.vue названия этих полей одни, а в profile.vue другие -->
              {{ note.owner.name ? note.owner.name : note.owner.first_name }}
              {{ note.owner.surname ? note.owner.surname : note.owner.last_name }}
            </div>
            <div class="date">{{ addOffset(note.created_at).format('lll') }}</div>
          </div>

          <div class="fav">
            <mu-checkbox v-model="note.is_favorite" uncheck-icon="star_outline" checked-icon="star"
                         @click="toggleNoteFav(note.id, note)"></mu-checkbox>
          </div>
        </div>

        <div class="content-text">
          <div class="note-title">{{ note.title }}</div>
          <div class="note-text" v-if="note.text">
            <truncate action-class="more-btn" collapsed-text-class="note-text-collapsed" :length="noteTruncateLength"
                      :less="$t('button.less')" :clamp="$t('button.more')"
                      :text="note.text"></truncate>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import truncate from 'vue-truncate-collapsed';
import {DateTimeHelper} from "../../../../helpers/date-time.helper";

export default {
  name: "notesList",
  components: {truncate},
  props: {
    notes: { // Notes list itself
      type: Array,
      default: function () {
        return [];
      }
    },
    showAll: { // Show all notes, not just 4
      type: Boolean,
      default: false
    },
    showActionButton: { // Display or don't display the «See all...»
      type: Boolean,
      default: false
    },
    showHeadline: { // Display or don't display the block headline
      type: Boolean,
      default: true
    },
    noteTruncateLength: {
      type: Number,
      default: 120
    },
  },
  data: function () {
    return {
      notesList: [],
    }
  },
  methods: {
    addOffset(dateTimeString) {
      return DateTimeHelper.getMomentWithOffset(dateTimeString);
    },
    setAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-300x300.png');
      }
      return noPhoto;
    },
    seeAllNotes() {
      this.$emit('seeAllNotes');
    },
    toggleNoteFav(id, note) {
      if (!note.is_favorite) {
        this.$store.dispatch('noteStore/removeFav', id)
      } else {
        this.$store.dispatch('noteStore/addFav', id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .field-block {
    margin-bottom: 3rem;
  }

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px;

    .title {
      text-align: left;
      font-family: Point, sans-serif;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0;
      color: $biletum-black;
      opacity: 0.8;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        left: -25px;
        bottom: 2px;
        content: "";
        border-bottom: 1px solid #eaeaea;
        width: 25px; /* Have to use precise units because we have overflow: visible on some field-blocks */
      }
    }
  }

  .note {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }

    .note-title {
      font-weight: bold;
      font-size: 1.2rem;
    }

    .note-text {
      font-size: 1.2rem;
    }

  }


  .btn {
    text-align: left;
    font-family: Point, sans-serif;
    font-size: 13px;
    color: #00b6f8;
    display: none;
  }

  .show-action {
    .btn {
      display: block;
    }
  }

  .note-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2rem;

    .note-meta {
      flex: 1;

      .author {
        font-weight: bold;
        font-size: 1.2rem;
      }

      .date {
        font-size: 1.1rem;
        opacity: 0.5;
      }
    }
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
<style lang="scss">
  /* Unscoped workaround: vue-truncate-collapsed adds own whitespace — new lines and spaces */
  .note .note-text span {
    white-space: pre-wrap;
  }

  .note .note-text span.note-text-collapsed {
    white-space: normal;
  }
</style>
