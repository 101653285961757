<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 22 22">
    <g id="Icon_ionic-ios-close-circle-outline" data-name="Icon ionic-ios-close-circle-outline"
       transform="translate(-3.375 -3.375)">
      <path id="Path_300" data-name="Path 300"
            d="M19.2,18.208l-2.32-2.32,2.32-2.32a.7.7,0,0,0-.995-.995l-2.32,2.32-2.32-2.32a.7.7,0,0,0-.995.995l2.32,2.32-2.32,2.32a.68.68,0,0,0,0,.995.7.7,0,0,0,.995,0l2.32-2.32,2.32,2.32a.707.707,0,0,0,.995,0A.7.7,0,0,0,19.2,18.208Z"
            transform="translate(-1.517 -1.515)"/>
      <circle cx="14.375" cy="14.375" r="11" fill="none" stroke="#000000" stroke-width="0.75"/>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconCloseCircleOutline = Vue.extend({
  name: 'icon-close-circle-outline',
  props: {
    width: {
      type: Number,
      default: 22,
    },
    height: {
      type: Number,
      default: 22,
    },
  },
});

export default IconCloseCircleOutline;
</script>
