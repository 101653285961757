<template>
  <div class="contact-calendar">
    <div class="meetings-date-selector">
        <span class="cur-p day"
              v-for="(dayObj, index) in eventDays"
              @click="selectDay(dayObj)"
              :class="{'day-selected' : selected_date === dayObj.date_obj}"
              :key="index"
        >
          {{ dayObj.day_number }}
        </span>
    </div>

    <div class="time-slots">
      <div class="time-slot"
           v-for="(timeSlot, index) in timeSlots"
           :key="index"
           :class="{
               'time-slot-available' : isTimeSlotAvailable(timeSlot),
               'time-slot-unavailable': !isTimeSlotAvailable(timeSlot)
             }"
           v-show="showTimeSlot(timeSlot)"
      >

        <!-- FOR MEETING CREATOR -->
        <div class="time-slot-content time-slot-content-creator" v-if="timeSlot.meeting && timeSlot.meeting.is_creator">
          <div class="time-range">
            <div class="from">{{ displayTime(timeSlot.date_start) }}</div>
            <div class="to">{{ displayTime(timeSlot.date_end) }}</div>
          </div>
          <div class="time-slot-info"
               v-if="timeSlot.meeting.status !== 'canceled'"
          >
            <div class="time-slot-avatar-wrap cur-p">
              <div class="avatar"
                   v-if="timeSlot.meeting.creator_contact.photo_url"
                   :style="{'background-image' : 'url(' + timeSlot.meeting.creator_contact.photo_url + ')' }"
              ></div>
              <div class="avatar avatar-no-image"
                   v-else
              ></div>
            </div>
            <div class="time-slot-contact">
              <div class="title text-left">{{ timeSlot.meeting.creator_contact.name }} {{
                timeSlot.meeting.creator_contact.surname }}
              </div>
            </div>
          </div>
          <div class="time-slot-info" v-else>
            <div class="time-slot-no-contact">
              <span
                v-if="isTimeSlotAvailable(timeSlot)"
              >{{ $t('meetings.available') }}</span>
              <span
                v-else
              >{{ $t('meetings.notAvailable') }}</span>
            </div>
          </div>
          <div class="time-slot-actions">
            <div class="time-slot-action time-slot-action-requestameeting"
                 v-if="isTimeSlotAvailable(timeSlot) && !isCurrentUserCalendarOwner()"
                 @click="requestMeeting(timeSlot, index)"></div>
            <div class="time-slot-action time-slot-action-cancel"
                 v-if="timeSlot.meeting && timeSlot.meeting.status !== 'canceled' && isMeetingMine(timeSlot)"
                 @click="cancelMeeting(timeSlot, index)"></div>
          </div>
          <div class="meeting-error"
               v-if="timeSlot.errors && timeSlot.errors.length"
          >
            <div class="meeting-error-text" v-for="(error, index) in timeSlot.errors" :key="index">
              {{ error.text }}
            </div>
            <div class="meeting-error-actions text-center">
                  <span class="meeting-error-action cur-p"
                        @click="clearMeetingErrors(timeSlot, index)">{{ $t('button.close') }}</span>
            </div>
          </div>
        </div>


        <!-- FOR THE PARTICIPANT, WHO IS NOT THE CREATOR -->
        <div class="time-slot-content time-slot-content-participant" v-else-if="timeSlot.meeting && !timeSlot.meeting.is_creator && timeSlot.meeting.user_contact.id === contactInfo.id">
          <div class="time-range">
            <div class="from">{{ displayTime(timeSlot.date_start) }}</div>
            <div class="to">{{ displayTime(timeSlot.date_end) }}</div>
          </div>
          <div class="time-slot-info"
               v-if="timeSlot.meeting.status !== 'canceled'"
          >
            <div class="time-slot-avatar-wrap cur-p">
              <div class="avatar"
                   v-if="timeSlot.meeting.user_contact.photo_url"
                   :style="{'background-image' : 'url(' + timeSlot.meeting.user_contact.photo_url + ')' }"
              ></div>
              <div class="avatar avatar-no-image"
                   v-else
              ></div>
            </div>
            <div class="time-slot-contact">
              <div class="title text-left">{{ timeSlot.meeting.user_contact.name }} {{
                  timeSlot.meeting.user_contact.surname }}
              </div>
            </div>
          </div>
          <div class="time-slot-info" v-else>
            <div class="time-slot-no-contact">
              <span
                v-if="isTimeSlotAvailable(timeSlot)"
              >{{ $t('meetings.available') }}</span>
              <span
                v-else
              >{{ $t('meetings.notAvailable') }}</span>
            </div>
          </div>
          <div class="time-slot-actions">
            <div class="time-slot-action time-slot-action-cancel"
                 v-if="timeSlot.meeting.status !== 'canceled'"
                 @click="cancelMeeting(timeSlot, index)"></div>
          </div>
          <div class="meeting-error"
               v-if="timeSlot.errors && timeSlot.errors.length"
          >
            <div class="meeting-error-text" v-for="(error, index) in timeSlot.errors" :key="index">
              {{ error.text }}
            </div>
            <div class="meeting-error-actions text-center">
                  <span class="meeting-error-action cur-p"
                        @click="clearMeetingErrors(timeSlot, index)">{{ $t('button.close') }}</span>
            </div>
          </div>
        </div>


        <!-- FOR THIRD PERSON -->
        <div class="time-slot-content time-slot-third-person" v-else-if="timeSlot.meeting && !timeSlot.meeting.is_creator">
          <div class="time-range">
            <div class="from">{{ displayTime(timeSlot.date_start) }}</div>
            <div class="to">{{ displayTime(timeSlot.date_end) }}</div>
          </div>
          <div class="time-slot-info">
            <div class="time-slot-no-contact">
              <span v-if="timeSlot.meeting.status !== 'confirmed'">{{ $t('meetings.available') }}</span>
              <span v-else>{{ $t('meetings.notAvailable') }}</span>
            </div>
          </div>
          <div class="time-slot-actions">
            <div class="time-slot-action time-slot-action-requestameeting"
                 v-if="timeSlot.meeting.status !== 'confirmed'"
                 @click="requestMeeting(timeSlot, index)"></div>
            <div class="time-slot-action time-slot-action-cancel"
                 v-if="timeSlot.meeting && timeSlot.meeting.is_creator && timeSlot.meeting.status !== 'canceled' && isMeetingMine(timeSlot)"
                 @click="cancelMeeting(timeSlot, index)"></div>
          </div>
          <div class="meeting-error"
               v-if="timeSlot.errors && timeSlot.errors.length"
          >
            <div class="meeting-error-text" v-for="(error, index) in timeSlot.errors" :key="index">
              {{ error.text }}
            </div>
            <div class="meeting-error-actions text-center">
                  <span class="meeting-error-action cur-p"
                        @click="clearMeetingErrors(timeSlot, index)">{{ $t('button.close') }}</span>
            </div>
          </div>
        </div>


        <!-- NO MEETING IN SLOT -->
        <div class="time-slot-content time-slot-content-no-meeting-in-slot" v-else>
          <div class="time-range">
            <div class="from">{{ displayTime(timeSlot.date_start) }}</div>
            <div class="to">{{ displayTime(timeSlot.date_end) }}</div>
          </div>
          <div class="time-slot-info">
            <div class="time-slot-no-contact">
              <span>{{ $t('meetings.available') }}</span>
            </div>
          </div>
          <div class="time-slot-actions">
            <div class="time-slot-action time-slot-action-requestameeting"
                 v-if="isTimeSlotAvailable(timeSlot) && !isCurrentUserCalendarOwner()"
                 @click="requestMeeting(timeSlot, index)"></div>
            <div class="time-slot-action time-slot-action-cancel"
                 v-if="timeSlot.meeting && timeSlot.meeting.status !== 'canceled' && isMeetingMine(timeSlot)"
                 @click="cancelMeeting(timeSlot, index)"></div>
          </div>
          <div class="meeting-error"
               v-if="timeSlot.errors && timeSlot.errors.length"
          >
            <div class="meeting-error-text" v-for="(error, index) in timeSlot.errors" :key="index">
              {{ error.text }}
            </div>
            <div class="meeting-error-actions text-center">
                  <span class="meeting-error-action cur-p"
                        @click="clearMeetingErrors(timeSlot, index)">{{ $t('button.close') }}</span>
            </div>
          </div>
        </div>


      </div>


    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import _cloneDeep from 'lodash.clonedeep';

export default {
  name: 'contactCalendar',
  computed: {
    ...mapState("userStore", ["user_info"]),   // used to obtain current user's id
    ...mapState("contactStore", ["callContact", "contact"]),
    ...mapState("meetingsStore", ["userSchedule", "getUserSchedule"]),
    ...mapState("eventStore", ["eventInfo"]), // used to obtain event's date from and date to
  },
  props: {
    userId: { // ид юзера, на список встреч которого мы смотрим
      type: Number,
      default: null
    },
    contactData: { // чуть-чуть данных про того юзера, на список встреч которого мы смотрим
      type: Object,
      default: function () {
        return {}
      }
    },
    promoPage: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    eventInfo: {
      deep: true,
      immediate: true,
      handler() {
        // Обнуление массивов
        this.eventDays = [];
        this.timeSlots = [];
        this.getEventDays(this.eventInfo);
        this.createTimeSlots();
        if (this.eventDays.length) {
          this.selected_date = this.eventDays[0].date_obj;
        }
      }
    },
    userSchedule: {
      deep: true,
      handler() {
        this.meetingsData = _cloneDeep(this.userSchedule);
        this.timeSlots = [];
        this.createTimeSlots();
      }
    },
    contact: {
      deep: true,
      immediate: true,
      handler() {
        this.contactInfo = this.contact;
      }
    },
    contactData: {
      handler() {
        this.timeSlots = [];
        this.createTimeSlots();
        this.getUserScheduleData();
      }
    }
  },
  data: () => ({

    meetingsData: {
      Limit: 1000,
      Offset: 0,
      Total: 0,
      List: [],
    },

    contactInfo: {},

    eventDays: [],
    timeSlots: [],

    selected_date: null
  }),
  created() {
    this.$store.dispatch('contactStore/callContact', this.$route.params.eventId);
  },
  mounted() {
    this.renderCalendar();
  },
  methods: {

    renderCalendar() {
      this.timeSlots = [];
      this.meetingsData.List = [];
      this.createTimeSlots();
      this.getUserScheduleData();
    },

    clearMeetingErrors(timeSlot, index) {
      const newItem = {..._cloneDeep(this.timeSlots[index])};
      newItem.errors = [];
      this.$set(this.timeSlots, index, newItem);
    },

    /* Проверка, смотрим ли сейчас текущий юзер на свой же календарь
     * например, чтобы из этого компонента нельзя было себе встречу добавить
     */
    isCurrentUserCalendarOwner() {
      return this.user_info && this.contactData && (this.user_info.id === this.contactData.user.id)
    },

    /* Проверка, сам ли создавал встречу
       */
    isMeetingMine(timeSlot) {
      return timeSlot.meeting.contact.user.id !== this.user_info.id
    },

    /* Проверка, сам ли создавал встречу
       */
    isMeetingConfirmed(timeSlot) {
      return timeSlot.meeting.status === 'confirmed';
    },

    /* Создаёт заявку на встречу в выбранное время
       */
    async requestMeeting(timeSlot, index) {

      let requestMeetingResponse = await this.$store.dispatch('meetingsStore/requestMeeting', {
        event_id: this.$route.params.eventId,
        user_id: this.contactData.user.id,
        date_start: timeSlot.date_start.replace(':00Z', ''),
        date_end: this.$moment.utc(timeSlot.date_end).subtract(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
      });

      if (!requestMeetingResponse || requestMeetingResponse.status !== 200 || requestMeetingResponse.error) {
        timeSlot.errors = timeSlot.errors || [];
        timeSlot.errors.push({
          text: requestMeetingResponse.error ? requestMeetingResponse.error : this.$t('errors.meetingGeneralError'),
        });
        this.$set(this.timeSlots, index, {...timeSlot});
        return;
      }

      if (requestMeetingResponse && requestMeetingResponse.data) {
        timeSlot.meeting = requestMeetingResponse.data;
      }

    },

    /* Отмена своей встречи
       */
    async cancelMeeting(timeSlot, index) {

      let cancelMeetingResponse = {
        status: 999
      };

      cancelMeetingResponse = await this.$store.dispatch('meetingsStore/cancelMeeting', {
        event_id: this.$route.params.eventId,
        meeting_id: timeSlot.meeting.id,
      });

      if (!cancelMeetingResponse || cancelMeetingResponse.status !== 202 || cancelMeetingResponse.error) {
        timeSlot.errors = timeSlot.errors || [];
        timeSlot.errors.push({
          text: cancelMeetingResponse && cancelMeetingResponse.error ? cancelMeetingResponse.error : this.$t('errors.meetingGeneralError'),
        });
        this.$set(this.timeSlots, index, {...timeSlot});
        return;
      }

      timeSlot.meeting = null;
      this.$set(this.timeSlots, index, {...timeSlot});

    },

    /* Обработчик клика по дню в .meeting-date-selector
       */
    selectDay(dayObj) {
      this.selected_date = dayObj.date_obj;
    },

    /* Список дней мероприятия для дизайна.
       * 10 11 12 и это вкладки списка.
       */
    getEventDays(ev) {
      let date_start = this.$moment.utc(ev.date_start.split('T')[0]); // split, чтобы не учитывать часы в разнице дней
      let date_end = this.$moment.utc(ev.date_end.split('T')[0]);
      let days_diff = date_end.diff(date_start, 'days');
      let iteratedDate; // Объект JS-даты для цикла

      // Заполнение массива
      for (let i = 0; i <= days_diff; i++) {
        if (i === 0) {
          iteratedDate = date_start.toDate();
        } else {
          iteratedDate = this.$moment(iteratedDate).add(1, 'days').toDate();
        }

        this.eventDays.push({
          formatted_db_style: this.$moment(iteratedDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
          date_obj: iteratedDate,
          day_number: this.$moment(iteratedDate).format('D').padStart(2, '0')
        });
      }
    },


    /* Почти хардкодим слоты времени дня, потому что
       * такую сущность на бэке решили не делать (на будущее)
       */
    createTimeSlots() {
      let date_from = '';
      let date_to = '';
      let minDays = this.eventDays.length || 1;
      let slotObj = {};

      for (let day = 0; day < minDays; day++) {
        for (let i = 0; i < 24; i++) {
          if (i < 9 || i > 21) {
            continue;
          }

          if (this.eventDays.length) {
            // n:00 — n:30
            date_from = this.$moment.utc(this.eventInfo.date_start).add(day, 'days').hours(i).minutes(0);
            date_to = this.$moment.utc(this.eventInfo.date_start).add(day, 'days').hours(i).minutes(30);
            let date_from_string = date_from.toDate().toISOString().replace('.000', '');
            slotObj = {
              date_start: date_from_string,
              date_end: date_to.toDate().toISOString().replace('.000', ''),
              meeting: this.findMeetingForTime(date_from_string),
              errors: []
            };
            this.timeSlots.push(slotObj);

            // n:30 — n+1:00
            date_from.hours(i).minutes(30);
            date_to.hours(i + 1).minutes(0);
            date_from_string = date_from.toDate().toISOString().replace('.000', '');
            slotObj = {
              date_start: date_from_string,
              date_end: date_to.toDate().toISOString().replace('.000', ''),
              meeting: this.findMeetingForTime(date_from_string),
              errors: []
            };
            this.timeSlots.push(slotObj);

          }
        }
      }
    },

    /* Returns null or a meeting
       */
    findMeetingForTime(timeSlot_date_start) {
      let result = null;
      if (this.meetingsData && this.meetingsData.List) {
        for (let i = 0; i < this.meetingsData.List.length; i++) {
          if (timeSlot_date_start === this.meetingsData.List[i].date_start && this.meetingsData.List[i].status !== 'canceled') {
            result = this.meetingsData.List[i];
            break;
          }
        }
      }
      return result;
    },

    /* Calls the action to populate
       * the page with data
       */
    getUserScheduleData() {

      if (!this.contactData || !this.contactData.user || !this.contactData.user.id) {
        setTimeout(this.getUserScheduleData, 150);
        return;
      }

      this.$store.dispatch('meetingsStore/getUserSchedule', {
        event_id: this.$route.params.eventId,
        user_id: this.contactData.user.id
      });

    },

    /* Returns a boolean
       * about time slot availability
       */
    isTimeSlotAvailable(timeSlot) {
      // Если нет встречи в слоте, значит, слот доступен
      if (!timeSlot.meeting) {
        return true;
      }

      // Если встреча подтверждена, наш бэк не дает создать новую заявку на то же время
      if (timeSlot.meeting.status === 'confirmed') {
        return false;
      }

      // Иначе встреча есть.
      //
      // Если абонент, на список встреч к-рого смотрим, сам же создал встречу,
      // значит, он пометил слот недоступным
      if((timeSlot.meeting.contact.id === timeSlot.meeting.user_contact.id)){
        return false;
      }

      //
      // Если встреча чужая, то оставляем слот свободным
      if((timeSlot.meeting.contact.id !== this.contactInfo.id) && (timeSlot.meeting.user_contact.id !== this.contactInfo.id)){
        return true;
      }

      // Если залогиненный не создатель, но участник, делаем слот занятым
      if (!timeSlot.meeting.is_creator && timeSlot.meeting.user_contact.id === this.contactInfo.id) {
        return false;
      }

      // Остается ситуация, что встреча принадлежит мне
      // В таком случае слот не свободен, чтобы я не мог нажимать несколько раз
      return false;

    },


    /* Проверка, должен ли слот времени быть показан при
       * текущем selected_dates[column_name]
       */
    showTimeSlot(timeSlot) {
      let date_start = this.$moment.utc(timeSlot.date_start);
      let date_current_day = this.$moment.utc(this.selected_date);

      return date_start.isSame(date_current_day, 'day');
    },


    /* Принимает дату в виде строки, как она из нашей базы приходит
       * возвращает строку 00:00
       */
    displayTime(dateString) {
      // Do not use timezone offset for now — removing the Z
      dateString = dateString.replace('Z', '');
      return this.$moment.utc(dateString).format("HH:mm");
    },

    /* Проверяет, есть ли встреча на этот слот времени
       */
    isTimeSlotBusy(timeSlot) {
      return !!timeSlot.meeting;
    },

  }
}
</script>

<style lang="scss" scoped>
  .meetings-date-selector,
  .time-slot {
    border-style: solid;
    border-width: 0 0 1px;
    border-color: rgba(112, 112, 112, 0.1);
    padding: 0 0 4px;
    text-align: center;


    .day {
      margin: 0 10px;
      display: inline-block;
      font-weight: 300;
      font-size: 1.2rem;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      color: #000;

      &::after {
        content: "";
        position: absolute;
        top: 105%;
        left: 0;
        width: 0;
        height: 1px;
        transition: width 0.5s;
        background-color: #00b6f8;
      }

      &-hover,
      &-selected {
        color: #00b6f8;

        &::after {
          width: 100%;
        }

      }
    }
  }


  .time-slot {
    padding: 12px 0;
    border-style: solid;
    border-width: 0 0 1px;
    position: relative;

    &:last-child {
      border-width: 0;
    }
  }

  .time-slot-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .time-range {
      width: 56px;
      font-weight: bold;
      text-align: left;
    }

    .time-slot-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 200px;

      .title {
        font-size: 13px;
        font-weight: bold;
      }

      .subtitle {
        font-size: 12px;
      }
    }

    .time-slot-avatar-wrap {
      width: 40px;
      min-height: 1px;

      .avatar {
        background-color: #ccc;
        width: 40px;
        height: 40px;
        border-radius: 99px;
        background-size: cover;
      }

      .avatar-no-image {
        background-image: url("../../../../assets/images/no-avatar-300x300.png");
      }
    }


    .time-slot-contact,
    .time-slot-no-contact {
      width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    .time-slot-actions {
      width: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .time-slot-action {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin: 0 6px;
      cursor: pointer;

      &:hover {
        opacity: 0.65;
      }

      &-disabled {
        opacity: 0.1;
        pointer-events: none;
      }
    }

    .time-slot-action-requestameeting {
      width: 21px;
      height: 20px;
      margin: 0;
      background-image: url("../../../../assets/images/icons/icon-meeting-markfree.png");
    }

    .time-slot-action-cancel {
      width: 13px;
      height: 13px;
      background-image: url("../../../../assets/images/icons/icon-ionic-md-close.png");
    }

    .time-slot-action-handshake {
      width: 22px;
      height: 16px;
      background-image: url("../../../../assets/images/icons/icon-meeting-handshake.png");
    }

  }

  /* hide unneeded buttons depending on slot availability */
  .time-slot-available .time-slot-action-cancel,
  .time-slot-available .time-slot-action-markfree,
  .time-slot-unavailable .time-slot-action-requestameeting,
  .time-slot-unavailable .time-slot-action-markbusy {
    display: none;
  }

  /* color of available | not available */
  .time-slot-unavailable .time-slot-no-contact {
    color: rgba(131, 0, 0, 1);
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
  }

  .time-slot-available .time-slot-no-contact {
    color: rgba(0, 96, 0, 1);
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
  }

  /* transition for time slots changed by .meetings-date-selector */
  .time-slots-fade-enter-active,
  .time-slots-fade-leave-active {
    transition: opacity 0.25s;
  }

  .time-slots-fade-enter,
  .time-slots-fade-leave-to { /* .fade-leave-active below version 2.1.8 */
    opacity: 0;
  }

  .meeting-error {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
    border-color: #d88;
    background-color: #fee;
    font-size: 1.2rem;

    .meeting-error-actions {
      font-variant: small-caps;
      font-size: 1.1rem;
      font-weight: 300;
      padding-top: 1rem;
      letter-spacing: 0.1em;
    }

    .meeting-error-action:hover {
      opacity: 0.5;
      display: inline-block;
      margin: 0 1em;
    }
  }

</style>
